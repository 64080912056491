import { useMemo } from 'react';
import i18n, { TFunction } from 'i18next';

const existTranslate = (key: string) => i18n.exists(`labels.${key}`);

export type UsePageNameReturn = {
    pageName: string;
};

export const usePageName = (
    pathname: string | undefined,
    t: TFunction
): UsePageNameReturn => {
    const pageName = useMemo(() => {
        const segments = pathname ? pathname.split('/').filter(Boolean) : [];
        const translatedParts = segments.map(segment => {
            if (existTranslate(segment)) {
                return i18n.t(`labels.${segment}`);
            }
            return segment;
        });
        return translatedParts.join(' - ');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, t]);

    return { pageName };
};
