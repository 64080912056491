// roleAccess.ts
// You can adjust resources and add new roles as needed.
export const roleAccess = {
    Expert: [
        'applications',
        'preliminary-applications',
        'preliminary-applications-wp',
        'applications-external',
        'form',
        'history',
        'clinical-trial',
        'menu-forms',
        'activity',
        'dictionaries',
    ],
    // Заявник
    ApplicantUser: [
        'applications',
        'preliminary-applications',
        'preliminary-applications-wp',
        'applications-external',
        'form',
        'history',
        'clinical-trial',
        'menu-forms',
    ],
    // Заявник те ж що і "ApplicantUser"
    ApplicantRoot: [
        'applications',
        'preliminary-applications',
        'preliminary-applications-wp',
        'applications-external',
        'form',
        'history',
        'clinical-trial',
        'menu-forms',
    ],
    RepresentativeUser: ['preliminary-applications-wp'],
    PreliminaryUser: ['preliminary-applications'],
};

// rolePriority.ts
// Numeric values represent how "strong" a role is.
// If a user has multiple roles, we pick the one with the highest value.
export const rolePriority: Record<string, number> = {
    ApplicantRoot: 1,
    ApplicantUser: 2,
    Expert: 3,
    RepresentativeUser: 4,
    PreliminaryUser: 5,
};
