import React, { useEffect, useMemo, useState } from 'react';
import { Refine, ResourceProps } from '@pankod/refine-core';
import { ErrorComponent } from '@pankod/refine-antd';
import './styles/antd.less';
import {
    Title,
    Header,
    Sider,
    Footer,
    PrivateLayout,
    OffLayoutArea,
} from 'components/layout';
import { useTranslation } from 'react-i18next';
import { Login } from 'pages/login';
import { api } from './utils/api';
import { TusClientProvider } from 'use-tus';
import { VisionProvider } from './context/vision';
import { BetterHelmet } from '@limeyfy/react-seo';
import { usePageName } from './hooks/usePageName';
import { useLocation } from 'react-use';
import { ConfigProvider } from 'antd';
import { dataProvider } from 'provider/dataProvider';
import { authProvider } from './authProvider';
import { notificationProvider } from './provider/notificationProvider';
import { NotificationContextProvider } from './context/NotificationContext';
import { Navigate } from 'react-router-dom';
import { useLocalizedResources } from './hooks/useLocalizedResources';
import { useUserData } from './hooks/useUserData';

import route from './config/route';
import { roleAccess, rolePriority } from './config/roleAccess';

import uk_UA from 'antd/es/locale/uk_UA';
import en_US from 'antd/es/locale/en_US';

function App() {
    const { t, i18n } = useTranslation();
    const { user } = useUserData();
    const resources = useLocalizedResources();
    const [newResources, setNewResources] = useState<
        ResourceProps[] | undefined
    >([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const combinedRoutes = [
        ...route?.routes,
        { path: '/', element: <Navigate to="/login" replace /> },
    ];

    const customRouterProvider = useMemo(
        () => ({
            ...route,
            routes: combinedRoutes,
        }),
        [combinedRoutes]
    );

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    const { pathname } = useLocation();
    const { pageName } = usePageName(pathname, t);

    const title = `${t('labels.unified_office_of_the_applicant')}: ${pageName}`;
    const locale = i18nProvider.getLocale();

    useEffect(() => {
        // Get the role with the highest numeric priority
        const getHighestPriorityRole = (roles: string[]): string | null => {
            let chosenRole: string | null = null;
            let maxPriority = -Infinity;
            roles.forEach(role => {
                // @ts-ignore
                const currentPriority = rolePriority[role] ?? 0;
                if (currentPriority > maxPriority) {
                    maxPriority = currentPriority;
                    chosenRole = role;
                }
            });
            return chosenRole;
        };

        const filterResourcesByRole = (items: any[]) => {
            // @ts-ignore
            const topRole = getHighestPriorityRole(user.roles);
            if (!topRole) return items; // if user has no recognized roles
            // @ts-ignore
            const allowed = roleAccess[topRole] ?? [];
            return items.filter(item => allowed.includes(item.name));
        };

        const filtered = user ? filterResourcesByRole(resources) : resources;
        setNewResources(filtered);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, user, t]);

    return (
        <>
            <TusClientProvider>
                <BetterHelmet>
                    <title>{title.trim()}</title>
                    <meta name="description" content={pageName} />
                </BetterHelmet>

                <NotificationContextProvider>
                    <ConfigProvider locale={locale === 'uk' ? uk_UA : en_US}>
                        <VisionProvider>
                            <Refine
                                key={i18n.language}
                                ReadyPage={() => null}
                                notificationProvider={notificationProvider}
                                catchAll={<ErrorComponent />}
                                routerProvider={customRouterProvider}
                                dataProvider={dataProvider(api)}
                                authProvider={authProvider}
                                LoginPage={Login}
                                resources={newResources}
                                Title={Title}
                                Header={Header}
                                Sider={Sider}
                                Footer={Footer}
                                Layout={PrivateLayout}
                                OffLayoutArea={OffLayoutArea}
                                i18nProvider={i18nProvider}
                            />
                        </VisionProvider>
                    </ConfigProvider>
                </NotificationContextProvider>
            </TusClientProvider>
        </>
    );
}

export default App;
