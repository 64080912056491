import {
    useTranslate,
    IResourceComponentsProps,
    HttpError,
    CrudFilters,
} from '@pankod/refine-core';
import {
    List,
    Table,
    TextField,
    useTable,
    getDefaultSortOrder,
    TagField,
    Col,
    Row,
    Button,
} from '@pankod/refine-antd';
import { IRecordFilterVariables, IRecord } from 'interfaces/activity.interface';
import { FC, useMemo, useState } from 'react';
import { Description } from '../../../components/activity/description';
import styles from '../../../components/activity/activiy.module.scss';
import { FilterPanel } from '../../../components/activity/filter-panel';

export const ActivityList: FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const [description, setDescription] = useState<any>(null);
    const [modal, setModal] = useState<boolean>(false);

    const { tableProps, searchFormProps, sorter } = useTable<
        IRecord,
        HttpError,
        IRecordFilterVariables
    >({
        onSearch: params => {
            const filters: CrudFilters = [];
            const { user_id, query } = params;

            filters.push(
                { field: 'query', operator: 'eq', value: query },
                { field: 'user_id', operator: 'eq', value: user_id }
            );

            return filters;
        },
    });

    const onShowDescription = (data: any) => {
        setModal(true);
        setDescription(data);
    };

    const memoLoader = useMemo(
        () => description === null,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [modal, description]
    );

    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <List
                    title={<FilterPanel searchForm={searchFormProps} />}
                    headerProps={{ className: styles.pageHeaderUtility }}
                >
                    <Table
                        {...tableProps}
                        rowKey="id"
                        scroll={{ x: 500 }}
                        bordered
                    >
                        <Table.Column
                            dataIndex={['causer', 'first_name']}
                            key="causer_id"
                            title={t('activity.fields.causer')}
                            render={value => <TextField value={value} />}
                        />
                        <Table.Column
                            dataIndex="log_name"
                            key="log_name"
                            title={t('activity.fields.log_name')}
                            render={value => <TextField value={value} />}
                            defaultSortOrder={getDefaultSortOrder(
                                'log_name',
                                sorter
                            )}
                            sorter
                        />
                        <Table.Column
                            dataIndex="description"
                            key="descripton_text"
                            title={t('activity.fields.description')}
                        />
                        <Table.Column
                            dataIndex="status"
                            key="status"
                            title={t('activity.fields.status')}
                            render={value =>
                                value ? <TagField value={value} /> : null
                            }
                            defaultSortOrder={getDefaultSortOrder(
                                'status',
                                sorter
                            )}
                            sorter
                        />
                        <Table.Column
                            dataIndex="created_at"
                            key="created_at"
                            title={t('activity.fields.created_at')}
                            render={value => <TagField value={value} />}
                            defaultSortOrder={getDefaultSortOrder(
                                'created_at',
                                sorter
                            )}
                            sorter
                        />
                        <Table.Column
                            dataIndex="description"
                            key="description"
                            title={t('activity.fields.description')}
                            render={(value, row: any) => {
                                let description = row.properties;

                                return (
                                    <Button
                                        onClick={() =>
                                            onShowDescription(description)
                                        }
                                    >
                                        Показати
                                    </Button>
                                );
                            }}
                        />
                    </Table>

                    <Description
                        visible={modal}
                        onCancel={() => {
                            setModal(false);
                            setDescription(null);
                        }}
                        description={description}
                        width={'90%'}
                        isLoading={memoLoader}
                    />
                </List>
            </Col>
        </Row>
    );
};
