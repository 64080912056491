import { useState, useEffect } from 'react';
import { IUser } from '../interfaces/auth.interface';

export function useUserData() {
    const [user, setUser] = useState<IUser | null>(null);

    const updateUser = () => {
        const data = localStorage.getItem('user');
        if (data) {
            setUser(JSON.parse(data));
        } else {
            setUser(null);
        }
    };

    useEffect(() => {
        updateUser();

        window.addEventListener('userUpdated', updateUser);
        return () => {
            window.removeEventListener('userUpdated', updateUser);
        };
    }, []);

    return { user, setUser };
}
