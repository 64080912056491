import { FC, useEffect, useState } from 'react';
import {
    Button,
    Col,
    Form,
    FormProps,
    Input,
    Row,
    Select,
} from '@pankod/refine-antd';
import { CrudFilters, useTranslate } from '@pankod/refine-core';

import styles from './Filter.module.scss';
import { useDictionarySelectProps } from '../../../hooks/preliminary-application-wp/useDictionarySelectProps';
import { useProcedureSelectProps } from '../../../hooks/useProcedureSelectProps';

interface IFilterProps {
    formProps: FormProps;
    onSetFilters: (filters: CrudFilters) => void;
}

export const Filter: FC<IFilterProps> = ({ formProps, onSetFilters }) => {
    const t = useTranslate();
    const { statusProps } = useDictionarySelectProps();
    const { CustomProcedureSelectProps, procedureQueryResult, procedureProps } =
        useProcedureSelectProps({
            filterMode: 'onlyPrice',
            optionLabel: 'name_for_applicant',
        });

    const [procedureOptions, setProcedureOptions] = useState<any[] | null>(
        null
    );

    useEffect(() => {
        const fetchData = async () => {
            const items = await CustomProcedureSelectProps();
            if (items && items.length > 0) {
                formProps.form?.setFieldsValue({
                    preliminary_request_procedure_id: items[0].value,
                });
            }
            setProcedureOptions(items);
        };
        fetchData();
    }, [procedureQueryResult.data]);

    useEffect(() => {
        if (
            procedureQueryResult &&
            procedureQueryResult.data?.data?.length! > 0
        ) {
            const procedureId = formProps.form?.getFieldValue(
                'preliminary_request_procedure_id'
            );

            onSetFilters([
                {
                    field: 'preliminary_request_procedure_id',
                    operator: 'eq',
                    value: procedureId,
                },
            ]);
        }
    }, [procedureOptions]);

    return (
        <Form layout="vertical" {...formProps} className={styles.filterWrapper}>
            <Row gutter={[16, 16]}>
                <Col lg={6} xs={24}>
                    <Form.Item
                        label={t('preliminary-applications.fields.reg_num')}
                        name="reg_num"
                    >
                        <Input
                            allowClear
                            placeholder={t(
                                'preliminary-applications.fields.reg_num'
                            )}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item
                        label={t('preliminary-applications.fields.state')}
                        name="state_id"
                    >
                        <Select
                            {...statusProps}
                            allowClear
                            placeholder={t(
                                'preliminary-applications.fields.state'
                            )}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item
                        label={t('preliminary-applications.fields.procedure')}
                        name="preliminary_request_procedure_id"
                    >
                        <Select
                            {...procedureProps}
                            options={procedureOptions ?? []}
                            allowClear
                            placeholder={t(
                                'preliminary-applications.fields.procedure'
                            )}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    {t('applications.buttons.filter')}
                </Button>
            </Form.Item>
        </Form>
    );
};
