import { FC } from 'react';
import {
    Button,
    Col,
    Form,
    FormProps,
    Input,
    Row,
    Select,
    useSelect,
} from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { IUser } from '../../../interfaces/activity.interface';
import styles from './filter.module.scss';

interface IFilterProps {
    searchForm: FormProps;
}

export const Filter: FC<IFilterProps> = ({ searchForm }) => {
    const { selectProps: userIds } = useSelect<IUser>({
        resource: 'dictionary/users',
        defaultValue: [],
        optionValue: 'id',
        optionLabel: 'first_name',
        onSearch: value => [
            {
                field: 'query',
                operator: 'contains',
                value,
            },
        ],
    });

    const t = useTranslate();

    return (
        <Form
            layout="vertical"
            {...searchForm}
            className={styles.filterWrapper}
        >
            <Row gutter={[16, 16]}>
                <Col lg={6} xs={24}>
                    <Form.Item label={t('activity.fields.query')} name="query">
                        <Input
                            allowClear
                            placeholder={t('activity.fields.query')}
                        />
                    </Form.Item>
                </Col>
                <Col lg={6} xs={24}>
                    <Form.Item label={t('activity.fields.user')} name="user_id">
                        <Select
                            {...userIds}
                            allowClear
                            placeholder={t('activity.fields.user')}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item>
                <Button htmlType="submit" type="primary">
                    {t('activity.buttons.filter')}
                </Button>
            </Form.Item>
        </Form>
    );
};
