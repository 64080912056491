import { Button, Form, Input, Spin } from '@pankod/refine-antd';
import { useTranslate, useLogin } from '@pankod/refine-core';
import React from 'react';
import { ILoginForm } from 'interfaces/auth.interface';
import { Link } from 'react-router-dom';
import { FilePdfOutlined } from '@ant-design/icons';

export const LoginForm = () => {
    const [form] = Form.useForm<ILoginForm>();
    const t = useTranslate();
    const { mutate: login, isLoading } = useLogin<ILoginForm>();

    return (
        <Spin spinning={isLoading}>
            <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={values => {
                    login(values);
                }}
                requiredMark={false}
                initialValues={{
                    user_name: '',
                    password: '',
                }}
            >
                <Form.Item
                    name="user_name"
                    label={t('pages.login.username')}
                    rules={[
                        {
                            required: true,
                            message: t('required_field'),
                        },
                    ]}
                >
                    <Input
                        size="large"
                        placeholder={t('pages.login.username')}
                        disabled={isLoading}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    label={t('pages.login.password')}
                    rules={[
                        {
                            required: true,
                            message: t('required_field'),
                        },
                    ]}
                >
                    <Input
                        type="password"
                        size="large"
                        placeholder={t('pages.login.password')}
                        disabled={isLoading}
                    />
                </Form.Item>
                <Form.Item style={{ marginBottom: 13 }}>
                    <Button type="primary" htmlType="submit" block size="large">
                        {t('pages.login.signin')}
                    </Button>
                </Form.Item>
            </Form>
            <div>
                <Link to="/cabinet-dec-instructions.pdf" target="_blank">
                    <FilePdfOutlined />{' '}
                    {t('pages.login.user_manual_for_the_applicants_profile')}
                </Link>
            </div>
            <div>
                <Link to="/cabinet-dec-instructions-eng.pdf" target="_blank">
                    <FilePdfOutlined /> User manual of the Applicant's cabinet
                </Link>
            </div>
        </Spin>
    );
};
