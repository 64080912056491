import { FC } from 'react';
import {
    useTranslate,
    IResourceComponentsProps,
    HttpError,
    CrudFilters,
} from '@pankod/refine-core';
import {
    List,
    Table,
    TextField,
    useTable,
    EditButton,
    Col,
    Row,
    Card,
    CreateButton,
} from '@pankod/refine-antd';
import { IApplication } from 'interfaces';

import { Filter } from '../../../components/preliminary-application/filter';
import buildSubmissionRow from '../../../utils/buildSubmissionRow';
import WidthLimiter from '../../../components/width-limiter';

interface IColumn {
    id: string;
    reg_num: string;
    reg_date: string;
    medication_name: string;
    applicant_id: number;
    procedure_id: number;
    state_id: number;
}

interface IRecordFilterVariables {
    query?: string;
    reg_num?: string;
    state_id?: string;
    preliminary_request_procedure_id?: string;
}

export const PreliminaryApplicationList: FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { tableProps, searchFormProps } = useTable<
        IApplication,
        HttpError,
        IRecordFilterVariables
    >({
        onSearch: params => {
            const filters: CrudFilters = [];
            const {
                state_id,
                reg_num,
                preliminary_request_procedure_id,
                query,
            } = params;

            filters.push(
                {
                    field: 'query',
                    operator: 'eq',
                    value: query,
                },
                {
                    field: 'reg_num',
                    operator: 'eq',
                    value: reg_num,
                },
                {
                    field: 'state_id',
                    operator: 'eq',
                    value: state_id,
                },
                {
                    field: 'preliminary_request_procedure_id',
                    operator: 'eq',
                    value: preliminary_request_procedure_id,
                }
            );

            return filters;
        },
        resource: 'preliminary-request',
    });

    return (
        <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
                <Card title={t('applications.titles.filters')}>
                    <Filter formProps={searchFormProps} />
                </Card>
            </Col>
            <Col lg={24} xs={24}>
                <List
                    pageHeaderProps={{
                        extra: [
                            <CreateButton key="create">
                                {t(
                                    'preliminary-applications.buttons.create_an_application_with_ev'
                                )}
                            </CreateButton>,
                        ],
                        title: t(
                            'preliminary-applications.preliminary-applications'
                        ),
                    }}
                >
                    <Table
                        {...tableProps}
                        rowKey="id"
                        pagination={{ ...tableProps.pagination }}
                        scroll={{ x: 500 }}
                        bordered
                    >
                        <Table.Column
                            dataIndex="medication_name"
                            key="medication_name"
                            title={t('applications.fields.medication_name')}
                            render={(value, row: IColumn) => (
                                <WidthLimiter>
                                    <TextField value={value} />
                                </WidthLimiter>
                            )}
                            width={'25%'}
                        />
                        <Table.Column
                            dataIndex=""
                            key=""
                            title={t('applications.fields.moz_data')}
                            render={(value, row: any) => {
                                const val = value
                                    ? buildSubmissionRow(
                                          row,
                                          ['reg_num', 'reg_date'],
                                          ' від '
                                      )
                                    : '';

                                return val && <TextField value={val} />;
                            }}
                            width={'25%'}
                        />
                        <Table.Column
                            dataIndex="state_id"
                            key="state_id"
                            title={t('applications.fields.state')}
                            render={(value, row: any) => (
                                <TextField value={row?.state?.name} />
                            )}
                            width={'25%'}
                        />
                        <Table.Column
                            title={t('table.actions')}
                            dataIndex="actions"
                            render={(_, record: any) => {
                                let itemId = record.id;

                                return (
                                    <EditButton
                                        hideText
                                        size="small"
                                        recordItemId={itemId}
                                    />
                                );
                            }}
                            width={'5%'}
                        />
                    </Table>
                </List>
            </Col>
        </Row>
    );
};
