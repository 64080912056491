import { useSelect } from '@pankod/refine-antd';
import { IProcedureItem } from 'interfaces/procedure.interface';

type TProcedureItem = {
    label: string;
    value: string | number;
};

type FilterMode = 'excludePrice' | 'onlyPrice' | 'none';

interface IUseProcedureSelectProps {
    /**
     * Field used as label (e.g. 'name' or 'name_for_applicant')
     */
    optionLabel?: keyof IProcedureItem;

    /**
     * Controls filtering by 'code'
     */
    filterMode?: FilterMode;
}

const excludeCodes = ['price', 'ClinicalChange', 'Clinical'];

export const useProcedureSelectProps = ({
    optionLabel = 'name_for_applicant',
    filterMode = 'none',
}: IUseProcedureSelectProps = {}) => {
    const { selectProps: procedureProps, queryResult: procedureQueryResult } =
        useSelect<IProcedureItem>({
            resource: 'dictionary/preliminary_request_procedure',
            optionLabel,
            onSearch: value => [
                {
                    field: 'query',
                    operator: 'contains',
                    value,
                },
            ],
        });

    const CustomProcedureSelectProps = async (): Promise<
        TProcedureItem[] | null
    > => {
        const data = procedureQueryResult?.data?.data;
        if (!data) return null;

        let filtered = data;
        if (filterMode === 'excludePrice') {
            filtered = data.filter(
                item => item.code && !excludeCodes.includes(item.code)
            );
        } else if (filterMode === 'onlyPrice') {
            filtered = data.filter(item => item.code === 'price');
        }

        return filtered.map(item => ({
            label: (item[optionLabel] as string) || item.name,
            value: item.id,
        }));
    };

    return {
        procedureProps,
        procedureQueryResult,
        CustomProcedureSelectProps,
    };
};
