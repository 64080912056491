import { useTranslation } from 'react-i18next';
import {
    ApplicationCreate,
    ApplicationEdit,
    ApplicationList,
} from '../pages/applications';
import {
    PreliminaryApplicationCreate,
    PreliminaryApplicationEdit,
    PreliminaryApplicationList,
} from '../pages/preliminary-application';
import {
    PreliminaryApplicationOPCCreate,
    PreliminaryApplicationOPCEdit,
    PreliminaryApplicationOPCList,
} from '../pages/preliminary-application-wp';
import { ActivityList } from '../pages/activity';
import { PharmasolList, PharmasolShow } from '../pages/pharmasol';
import SpecList from '../pages/specs/list/list';
import SpecShow from '../pages/specs/show/show';
import {
    ExperimentOutlined,
    FormOutlined,
    FundProjectionScreenOutlined,
} from '@ant-design/icons';

export const useLocalizedResources = () => {
    const { t } = useTranslation();

    return [
        {
            name: 'applications',
            list: ApplicationList,
            create: ApplicationCreate,
            edit: ApplicationEdit,
            icon: <FormOutlined />,
            options: {
                label: t('applications.applications'),
            },
        },
        {
            name: 'preliminary-applications',
            list: PreliminaryApplicationList,
            create: PreliminaryApplicationCreate,
            edit: PreliminaryApplicationEdit,
            icon: <FormOutlined />,
            options: {
                label: t('preliminary-applications.preliminary-applications'),
            },
        },
        {
            name: 'preliminary-applications-wp',
            list: PreliminaryApplicationOPCList,
            create: PreliminaryApplicationOPCCreate,
            edit: PreliminaryApplicationOPCEdit,
            icon: <FormOutlined />,
            options: {
                label: t(
                    'preliminary-applications-wp.preliminary-applications'
                ),
            },
        },
        {
            name: 'applications-external',
            list: () => null,
            options: {
                label: t(
                    'applications_external_page.applications_external_page'
                ),
            },
        },
        {
            name: 'form',
            list: () => null,
            options: { label: t('form') },
        },
        {
            name: 'history',
            list: () => null,
            options: { label: t('history') },
        },
        {
            name: 'clinical-trial',
            list: () => null,
            options: { label: t('clinicaltrial_items') },
        },
        {
            name: 'menu-forms',
            list: () => null,
            options: { label: t('menuforms') },
        },
        {
            name: 'activity',
            list: ActivityList,
            icon: <FundProjectionScreenOutlined />,
            options: { label: t('activity.activity') },
        },
        {
            name: 'dictionaries',
            list: PharmasolList,
            show: PharmasolShow,
            icon: <ExperimentOutlined />,
            options: { label: t('pharmasol.dictionaries') },
        },
        {
            name: 'specs',
            list: SpecList,
            show: SpecShow,
        },
    ];
};
