import React, { useState, useEffect, FC, useCallback } from 'react';
import { IResourceComponentsProps, useNavigation } from '@pankod/refine-core';
import {
    Button,
    Edit,
    ListButton,
    Popconfirm,
    SaveButton,
    Space,
    useForm,
} from '@pankod/refine-antd';
import { useParams } from 'react-router-dom';
import { api } from '../../../utils/api';
import { IApplication } from '../../../interfaces';
import { TranslatedBreadcrumbs } from '../../../components/translated-breadcrumbs';
import { useTranslation } from 'react-i18next';
import { PreliminaryApplicationInfo } from '../../../components/preliminary-application';
import { message } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { useNotification } from '../../../hooks/useNotification';
import dayjs from 'dayjs';
import { CheckOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/es/form';
import {
    FormFields,
    IFormValues,
} from '../../../interfaces/preliminary-application.interface';
import { buildFormData } from '../../../utils/buildFormData';
import { handleFormError } from '../../../utils/error-handlers';
import { isValidValue } from '../../../utils/obj';
import { pickBy } from 'lodash';
import { sleep } from '../../../utils/sleep';
import { validateAndAppendFiles } from '../../../utils/validateAndAppendFiles';
import { useTitle } from '../../../hooks/useTitle';
import { mapStoreToFormValues } from '../../../utils/mapStoreToFormValues';

const LOADER_DELAY_IN_MS: number = 2000;

export const PreliminaryApplicationEdit: FC<IResourceComponentsProps> = () => {
    const { msgSuccess, msgError } = useNotification();
    const { id } = useParams();
    const { t } = useTranslation();
    const { replace } = useNavigation();

    const {
        formProps: appProps,
        saveButtonProps,
        formLoading: isLoading,
    } = useForm<IApplication>({
        action: 'edit',
        resource: 'preliminary-request',
        queryOptions: { retry: false },
        id,
    });

    const form = appProps?.form;
    const initialValues = appProps?.initialValues;

    const [isReadOnlyForm, setIsReadOnlyForm] = useState(
        initialValues?.state_id
    );
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [files, setFiles] = useState([]);
    const [disSubmit, setDisSubmit] = useState(false);
    const [isFormProcessing, setIsFormProcessing] = useState<boolean>(false);

    useEffect(() => {
        if (initialValues?.state_id) {
            setIsReadOnlyForm(true);
            setDisSubmit(true);
        }
    }, [initialValues]);

    const formValues: IFormValues | undefined = initialValues
        ? mapStoreToFormValues(initialValues)
        : undefined;

    const generateFormTitle = (form: IFormValues): string => {
        return form?.name || form.uuid
            ? `${t(
                  'preliminary-applications.titles.working_with_the_application'
              )} ${form?.name || form.uuid}`
            : '';
    };

    useEffect(() => {
        if (initialValues?.file) {
            setFileList(initialValues.file);
        }

        if (initialValues?.files) {
            setFiles(initialValues?.files);
        }
    }, [initialValues]);

    const handleFileChange = useCallback(
        (newFileList: UploadFile[]) => {
            setFileList(newFileList);
            // @ts-ignore
            form.setFieldsValue({ file: newFileList });
        },
        [form]
    );

    const resetStatesHandler = (
        fields?: FormFields,
        // @ts-ignore
        formInstance: FormInstance = form
    ): void => {
        setIsFormProcessing(false);
        setDisSubmit(false);
        setFileList([]);

        if (fields) {
            formInstance.resetFields(fields);
        } else {
            formInstance.resetFields();
        }
    };

    const handleFinish = async (values: IFormValues) => {
        try {
            setIsFormProcessing(true);

            const payload = {
                ...values,
                reg_date: values?.reg_date
                    ? dayjs(values?.reg_date).format('DD.MM.YYYY')
                    : null,
                _method: 'PUT',
            };
            const filteredValues = pickBy(payload, isValidValue);

            const formData = buildFormData(filteredValues, 'files');

            await validateAndAppendFiles({
                fileList,
                formData,
                form: form!,
                resetStates: (fields, formInstance) =>
                    resetStatesHandler(fields, formInstance),
                message,
                sleep,
                LOADER_DELAY_IN_MS,
            });

            const {
                data: { data },
            } = await api.post(`/api/preliminary-request/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            await sleep(LOADER_DELAY_IN_MS);
            setFiles(data?.files);
            resetStatesHandler(['files'], form);
            msgSuccess(
                t(
                    'notifications.the_upload_is_complete_wait_for_the_successful_processing_status_check_the_application_processing_status_after_a_while'
                )
            );

            setIsFormProcessing(false);
        } catch (error: any) {
            console.error(error);
            handleFormError({
                e: error,
                form: form!,
                setIsFormProcessing,
                setDisSubmit: () => null,
                msgError,
                t,
            });
        }
    };

    const onSendToDec = async (recordId: string | undefined) => {
        try {
            setIsFormProcessing(true);

            if (!recordId) {
                setIsFormProcessing(false);
                return;
            }

            const response = await api.post(
                `/api/preliminary-request/${recordId}/send`
            );

            if (response.status === 200) {
                let state = response.data.data?.state?.name;
                if (state) {
                    // @ts-ignore
                    appProps.form.setFieldsValue({ state_id: state });
                }
                await sleep(LOADER_DELAY_IN_MS);
                setIsFormProcessing(false);
                setIsReadOnlyForm(true);
                setDisSubmit(true);
                msgSuccess(t('notifications.sent_to_the_dec'));
            }
        } catch (e) {
            console.error(e);
            msgError(t('notifications.entry_error'));
            setIsFormProcessing(false);
            setIsReadOnlyForm(false);
            setDisSubmit(false);
        }
    };

    return (
        <Edit
            pageHeaderProps={{
                extra: [<ListButton key="listBtnKey" />],
                breadcrumb: <TranslatedBreadcrumbs />,
                title: useTitle(formValues, generateFormTitle),
                onBack: () => replace('/preliminary-applications'),
            }}
            actionButtons={[
                <Space align="center">
                    <Popconfirm
                        placement="top"
                        title={t('are_you_sure')}
                        onConfirm={() => onSendToDec(id)}
                        okText={t('yes')}
                        cancelText={t('no')}
                    >
                        <Button
                            htmlType="button"
                            type="primary"
                            icon={<CheckOutlined />}
                            disabled={disSubmit || isFormProcessing}
                        >
                            {t('transfer_to_the_dec')}
                        </Button>
                    </Popconfirm>
                    ,
                    <SaveButton
                        {...saveButtonProps}
                        disabled={disSubmit || isFormProcessing}
                    />
                </Space>,
            ]}
        >
            <PreliminaryApplicationInfo
                formProps={appProps}
                isLoading={isLoading}
                isFormProcessing={isFormProcessing}
                handleFinish={handleFinish}
                fileList={fileList}
                handleFileChange={handleFileChange}
                isReadOnlyForm={isReadOnlyForm}
                uploaded={files}
            />
        </Edit>
    );
};
